$vars: ( 
	ratio: (small: 0.84, big: 2),
	type-font: (big: 2, small: 1),
	theme-webcams:(
		ratio: (small: 0.67, big: 1),
		type-font: (big: 1.12, small: 0.8),
		webcam-error: (
			margin: (
				big: (30px auto auto auto),
				small: ( 10px auto auto auto),
				
			)
		)

	)
);

$simpleMapVars:(
	leaflet-top--top: (								small: 44px, 							standard: 76px, 								big: 100px											),
	leaflet-bar--box-shadow:( 						small: 0 2px 6px rgba(0,0,0,0.65), 		standard: 0 2px 6px rgba(0,0,0,0.65), 			big: 0 2px 10px rgba(0,0,0,0.65)					),
	leaflet-top--margin-top:(						small: 12px, 							standard: 18px, 								big: 18px											),
	leaflet-bar-a--width:( 							small: 42px, 							standard: 50px,	 								big: 100px											),
	leaflet-bar-a--height:( 						small: 42px, 							standard: 50px,	 								big: 100px											),
	leaflet-bar-a--size:( 							small: 42px, 							standard: 50px,	 								big: 100px											),
	weatherPopup-ul--li--height:(					small: 30px, 							standard: 50px,	 								big: 100px											),
	leaflet-customButtonBar--a--size:(				small: 60px, 							standard: 76px,	 								big: 120px											),
	trafficalert-list-item-content--font-size:(		small: 1.15em, 							standard: 1.25em,	 							big: 1.4em											),
	trafficalert-list-item-title--font-size:(		small: 1.4em, 							standard: 1.45em,	 							big: 1.7em											),
	webcam-thumbnail--width:	(					small: 120px, 							standard: 180px,	 							big: 180px											),
	webcam-thumbnail--height:	(					small: 80px, 							standard: 120px,	 							big: 120px											),
	webcam-player--width:	(						small: 240px, 							standard: 384px,	 							big: 384px											),
	webcam-player--height:	(						small: 180px, 							standard: 288px,	 							big: 288px											),
	webcam-player--line-height:	(					small: 20px, 							standard: 22px ,	 							big: 22px 											),
	webcam-player--font-size:(						small: 1.3em, 							standard: 1.5em,	 							big: 1.5em											),
	trip-time--font-size:(							small: 1.3em, 							standard: 1.55em,	 							big: 1.75em											),
	trip-time-list--font-size:(						small: 1.25em, 							standard: 1.45em,	 							big: 1.55em											),
	trip-time-origin--font-size:(					small: 12px, 							standard: 16px,	 								big: 19px											),
	trip-time-origin--height:(						small: 30px, 							standard: 40px,	 								big: 70px											),
	trip-time-origin--top:(							small: 10px, 							standard: 14px,	 								big: 36px											),
	trip-time-list-header--height:(					small: 75px, 							standard: 80px,	 								big: 90px											),
	trip-time-list-header--width:(					small: 80%, 							standard: 305px,	 							big: 450px											),
	trip-time-description-global-list--width:(	  	small: 60%, 							standard: 250px,								big: 320px											),
	trip-time-display-global-list--width:(	  		small: 34%, 							standard: 100px,								big: 130px											),
	forecast-title--left:(	  						small: 60px, 							standard: 80px,									big: 100px											),
	forecast-title--width:(	  						small: 80%, 							standard: 450px,								big: 650px											),
	forecast-title--font-size:(	  					small: 1.8em, 							standard: 3em,									big: 3em											),
	forecast-datetime--font-size:(	  				small: 1.1em, 							standard: 1.3em,								big: 1.6em											),
	forecast-button-date--height:(	  				small: 45px, 							standard:60px,									big: 100px										),
	forecast-button-date--font-size:(	  			small: 3em, 							standard:4em,									big: 5em										),
	forecast-button-date-month--font-size:(			small: 1em, 							standard:1.2em,									big: 1.8em										),
	trafficforecast-group-popin-title--height:(		small: 40px, 							standard:50px,									big: 60px										),
	trafficforecast-group-popin-title--font-size:(	small: 1.25em, 							standard:1.45em,								big: 1.9em										),
	img-forecast--size:(	  						small: 130px, 							standard: 200px,								big: 300px											),
	leaflet-popin-content-blur--height:(			small: 26px, 							standard:40px,									big: 60px										),
	leaflet-menu-bar--height:(						small: 46px, 							standard:62px,									big: 84px										),

	leaflet-sidebar-item--width:(						small: 200px, 							standard:300px,									big: 400px										),
	leaflet-sidebar-item--height:(						small: 60px, 							standard:85px,									big: 100px										),
);

$leaflet-version: '1.0.3';


$font01: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font02: arial;
$font03: Optima, Segoe, 'Segoe UI', Candara, Calibri, Arial, sans-serif;

$transparent0:hsla(0,0%,100%,0); //	hsla(0,0%,100%,0) hsla(0,0%,100%,0) 0 //generer par des gradiants
$transparent45: rgba(0,0,0,.45); //rgba(0,0,0,.45)
$transparent50:	hsla(0,0%,6%,.5);//hsla(0,0%,6%,.5)
$transparent65: rgba(0,0,0,.65);//rgba(0,0,0,.65)

$white:#fff; //#fff;

$grey01:	#ecf0f1;   	//#ecf0f1
$grey02:	#b2b2b2;   	//#b2b2b2
$grey03:	#ccc;   	//#ccc
$grey04:	#d0d0d0;   	//#d0d0d0
$grey05:	#9a9a9a;   	//#9a9a9a
$grey06:	#707070;   	//#707070	
$grey07:	#606060;   	//#606060
$grey08:	#555555;   	//#555
$grey09:	#404040;   	//#404040
$grey10:	#1d1d1b;   	//#1d1d1b
$grey11:	#1d1d1d;   	//#1d1d1d

$blues01:#eeeeff;	//#eef
$blues02:#2e65b7;	//#2e65b7
$blues03:#2d64b6;	//#2d64b6
$blues04:#3669b2;	//#3669b2

$red01:#ab0404;	//#ab0404
$red02:#bb0000;		//#b00
$red03:#c00000;	//#c00000

$green01:#00aa00;//#0a0
$green02:#009900;//#090
	
$orange01: #ff9900; //#f90 

$salmon01: #f79646; //#f79646 
	
$purple01:#b549ee;//#b549ee
	
$black:#000000; //#000