.forecast-icon-class
{
	@include sprite($icons-marker-traffic-forecast-unknown); 
}
.forecast-icon-class.heavy
{
	@include sprite($icons-marker-traffic-forecast-orange); 

}
.forecast-icon-class.saturated
{
	@include sprite($icons-marker-traffic-forecast-red); 
}
.forecast-icon-class.freeflow
{
	@include sprite($icons-marker-traffic-forecast-green); 
}
.forecast-icon-class.unknown
{
	@include sprite($icons-marker-traffic-forecast-green); 
}

/*************TODO VVV ******************/

/* Forecast */
div.forecast-splash-screen
{
	width: 100%;
	/*min-height: 350px;*/
	overflow-y: scroll;
	height: 100%;
	position: absolute;
	background-color: $white;
	z-index: 9999;

	div.forecast-title
	{
		@include partialSpriteBefore($icons-icon-menu-traffic-forecast);

		margin: simpleMap(forecast-title--left) auto 0 auto;
		width:simpleMap(forecast-title--width);
		/*background-image: url(<?php echo $WT3_BaseUrl; ?>/icons/100/IconMenuTrafficForecast.png);
		background-repeat: no-repeat;
		*/
		padding-left: simpleMap(forecast-title--left) - 10;
		@if($mode==standard)
		{
			padding-left: simpleMap(forecast-title--left);
		}
		color: $blues02;
	
		h1.forecast-title
		{
			padding: 7px 0 0 0;
			@if($mode==small)
			{
				padding: 4px 0 0 0;
			}
			margin: 0;
			font-size: simpleMap(forecast-title--font-size);
			font-weight: 500;
		}
		p.forecast-subtitle
		{
			padding: 0;
			margin: 0;
			font-size: 1.2em;
			@if($mode==small)
			{
				font-size: 1.1em;
			}
			line-height: 1em;
			font-style: italic;
		}
	}
	
	.forecast-select
	{	
		color: $blues02;
	}
	div.forecast-select
	{
		
		margin: simpleMap(forecast-title--left)/2 auto 0 auto;
		@if($mode==big)
		{
			margin: 80px auto 0 auto;
		}
		padding: 4px 0 0 0;
		width:simpleMap(forecast-title--width);
		@if($mode==standard)
		{	
			width:  50%;
		}
	}
	p.forecast-select
	{
		font-size: 1.6em;
		margin: 0 0 20px 0;
	}
	label.forecast-datetime
	{
		display: inline-block;
		font-size: simpleMap(forecast-datetime--font-size);
		width: 18%;
		@if($mode==big)
		{
			width: 68px;
			margin-right: 5px;

		}
		@if($mode==small)
		{
			min-width: 25px;
			box-sizing: border-box;
		}
		margin: 0;
		padding: 0;
	}
	input.forecast-select
	{
		display: inline-block;
		border: solid 1px $grey03;
		text-align: center;

		width: 81%;
		height: 2em;
		line-height: 2em;
		font-size: 2em;
		margin: 0 0 20px 0;
		padding: 0;
		@if($mode==big)
		{

			width: 573px;
			height: 2.5em;
			font-size: 2.3em;
			margin-bottom: 20px;
		}
		@if($mode==small)
		{
			width: 80%;
			height: 1.8em;
			font-size: 1.4em;
			margin-bottom: 15px;
			box-sizing: border-box;
		}
	}
	select.forecast-select
	{
		display: inline-block;
		border: solid 1px $grey03;
		text-align: center;
		overflow-y: scroll;
		cursor: pointer;
		width: 81%;
		height: 2em;
		line-height: 2em;
		vertical-align: middle;
		text-align-last:center;
		font-size: 2em;
		margin: 0 0 30px 0;
		padding: 0;
		@if($mode==big)
		{
			width: 574px;
			height: 2.5em;
			text-align-last: center;
			font-size: 2.3em;
			margin-bottom: 40px;
		}
		@if($mode==small)
		{
			width: 81%;
			height: 1.8em;
			font-size: 1.4em;
			margin-bottom: 15px;
			box-sizing: border-box;
			-webkit-appearance: menulist;
		}
	}
	@if($mode==big){
		select.forecast-select option
		{
			text-align: center;
		}
	}
	button.forecast-select
	{
		display: block;
		width: 100%;
		min-height: 2em;
		text-align: center;
		font-size: 1.8em;
		color: #777777;
		background-color: $grey03;
		cursor: auto;
		border: none;
		@if($mode==big){
			min-height: 2.5em;
			font-size: 2.3em;
		}
		@if($mode==small)
		{
			font-size: 1.2em;
			margin-bottom: 15px;
		}
	}
	button.forecast-select.enabled
	{
		color: $white;
		background-color: $blues02;
		cursor: pointer;
	}
}
.leaflet-customButtonBar-forecastdateselectscreen
{
	display: inline-block;
	min-width: 70px;
	@if($mode==small)
	{
		position: fixed;
		bottom: 0;
		left: 0;
	}
}
div.forecast-button
{
	font-family: $font01;
	@if($mode==small)
	{
		color: $blues02;
	}
}
div.forecast-button.date
{
	padding: 0;
	margin: 0;
	line-height:simpleMap(forecast-button-date--height);
	font-size: simpleMap(forecast-button-date--font-size);
	vertical-align: bottom;
	@if($mode!=small){

		color: $blues02;
	}
	font-weight: 700;
}
div.forecast-button.month
{
	padding: 0;
	margin: 0;
	line-height:0px;
	font-size:simpleMap(forecast-button-date-month--font-size);
	vertical-align: top;
	@if($mode!=small){

		color: $blues02;
	}
}
.leaflet-customButtonBar-forecasttimeslide
{
	display: inline-block;
	width: 250px !important;
	font-size: 4.5em;
	color: $blues02 !important;
	font-weight: 700;
	@if($mode==big){
		width: 350px !important;
		font-size: 5.5em;
	}
	@if($mode==small){
		font-size: 3em;
		right:0;
		width: 100% !important;
		border-right: none !important;
		background-color: $white;
		height: 100%;
	}
}
.leaflet-customButtonBar-forecasttimeslide div
{
	display: inline-block;
	//position: relative;
}
.leaflet-customButtonBar-forecasttimeslide > div
{
	width: 100%;
}
div.forecast-button.time
{
	
	position: relative;
	width:70%;
}
@if($mode!=small){
	div.forecast-button.time
	{
		//width: 170px;
		@if($mode==big){
			width: 230px;
			height: 120px;
			vertical-align: top;
		}
	}
}
div.forecast-button.time.rushhour
{
	color: $red03;
	
	
}
p.rushhour
{
	font-size: 0.25em;
	padding-top: 8px;
	top: 6px;
	@if($mode==big){
		top: 23px;
	}
	position: absolute;
	text-align: center;
	left: 0;
	right: 0;
	width: 100%;
	@if($mode==small){
		padding: 0;
		margin: 0;
		top: 20px;
		font-size: 0.3em;
	}

}
div.forecast-button.previous
{
	@include sprite($buttons-previous-hour-traffic-forecast-button);
	transform: translateY((simpleMap(leaflet-customButtonBar--a--size)  - nth($buttons-previous-hour-traffic-forecast-button, 6))/2 );

	/*
	width: 40px;
	height: 76px;
	background: url(<?php echo $WT3_BaseUrl; ?>/buttons/50/previousHourTrafficForecastButton.png) center center no-repeat;
	*/
	vertical-align: top;
	@if($mode==small){
		left: 0;
		right: auto;
		position: absolute;
	}
}
div.forecast-button.previous.unavailable
{
	@include sprite($buttons-previous-hour-traffic-forecast-button-unavailable);
	transform: translateY((simpleMap(leaflet-customButtonBar--a--size)  - nth($buttons-previous-hour-traffic-forecast-button, 6))/2 );
	//background: url(<?php echo $WT3_BaseUrl; ?>/buttons/50/previousHourTrafficForecastButtonUnavailable.png) center center no-repeat;
}
div.forecast-button.next
{
	/*
	width: 40px;
	height: 76px;
	background: url(<?php echo $WT3_BaseUrl; ?>/buttons/50/nextHourTrafficForecastButton.png) center center no-repeat;
	*/
	@include sprite($buttons-next-hour-traffic-forecast-button);
	transform: translateY((simpleMap(leaflet-customButtonBar--a--size)  - nth($buttons-previous-hour-traffic-forecast-button, 6))/2 );
	vertical-align: top;
	@if($mode==small){
		right: 0;
		left: auto;
		position: absolute;
	}
}
div.forecast-button.next.unavailable
{
	@include sprite($buttons-next-hour-traffic-forecast-button-unavailable);
	transform: translateY((simpleMap(leaflet-customButtonBar--a--size)  - nth($buttons-previous-hour-traffic-forecast-button, 6))/2 );
	//background: url(<?php echo $WT3_BaseUrl; ?>/buttons/50/nextHourTrafficForecastButtonUnavailable.png) center center no-repeat;
}




/* traffic forecast popin */
div.leaflet-popin-title.forecast
{
	background-color: $grey10;
}
div.leaflet-popin-title.forecast span span
{
	font-size: 0.75em;
}
div.trafficforecast-group-popin-title
{
	width:96%;
	height: simpleMap(trafficforecast-group-popin-title--height);
	font-size: simpleMap(trafficforecast-group-popin-title--font-size);
	font-weight: 700;
	margin: 0 auto 15px auto;
	@if($mode==small)
	{
		margin: 0 auto 10px auto;
	}
}
div.trafficforecast-group-popin-title-icon
{
	@include sprite($icons-highway-icon);
	/*transform: scale(0.6);*/
	/*
	width: 50px;
	height: 50px;
	background-image : url(<?php echo $WT3_BaseUrl; ?>/icons/50/highwayIcon.png);
	background-repeat : no-repeat;
	background-size : 100% 100%;
	*/
	margin: auto 0;
	display: table-cell;
}
div.trafficforecast-group-popin-title-description
{
	display: table-cell;	
	height:simpleMap(trafficforecast-group-popin-title--height);
	/*line-height: simpleMap(trafficforecast-group-popin-title--height);;*/
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle;
}
img.forecast
{
	width: simpleMap(img-forecast--size);
	height:simpleMap(img-forecast--size);
}
div.forecast-description
{
	/*
	width: 160px;
	@if($mode==big){
		width: 225px;
	}
	*/
	
	width: 42%;
	@if($mode==small)
	{
		width: 52%;
	}
	margin-left: 10px;
	vertical-align: top;
	display: inline-block;
	box-sizing: border-box;

	
	font-size: 1.4em;
	box-sizing: border-box;
	
	font-weight: 700;
	cursor: pointer;
	line-height: 1.4em;
	

	@if($mode==standard){
		//width: 174px;
	}
	@if($mode==big){
		//width: 220px;
		padding: 10px;
		font-size: 1.7em;
		line-height: 1.7em;
	}
	
	@if($mode==small)
	{
		font-size: 1.2em;
		line-height: 1.2em;
		margin-bottom: 5px;
	}
	div.forecast-direction{
		vertical-align: middle;
		@include partialSpriteAfter($buttons-next-hour-traffic-forecast-button,-$buttons-next-hour-traffic-forecast-button-width);
	/*	&:before{
			    margin-top: 50%;
    			transform: translateY(-100%);
		}*/
		padding: 3px;
		border : 1px solid $blues02;
		margin-bottom: 20px;
		width: 100%;
		p.forecast-direction
		{
			padding: 0;
			margin: 0;
			@if($mode==standard){
				width: 130px;
			}
			@if($mode==big){
				width: 180px;
			}
			@if($mode==small)
			{
				padding: 3px 24px 0 0;
			}
			&.origin{
				margin-bottom: 3px;
				@if($mode==big){
					margin-bottom: 10px;
				} 
			}
			&.destination
			{
				@include partialSpriteBefore($other-to,6px,2px);

				@if($mode==small){
					@include partialSpriteBefore($other-to,6px,0px);
				}
				
				@if($mode==big){
					@include partialSpriteBefore($other-to,6px,4px);
				}

			}
		}
	}
	
}


/* Traffic forecast section list */

a.leaflet-customButtonBar-forecastlist
{
	@include sprite($buttons-highway-group-button);
	
	//background-image: url(<?php echo $WT3_BaseUrl; ?>/buttons/74/highwayGroupButton.png);
}
h2.traffic-forecast-group-list
{
	width: 92%;
	margin: 20px auto !important;
	font-size: 1.3em;
	@if($mode==big){
		margin: 25px auto !important;
		font-size: 1.5em;
	}
	@if($mode==small)
	{
		margin: 15px auto !important;
		font-size: 1.25em;
	}
}
.trafficforecast-group-list-item
{
	font-size: 1.4em;
	font-weight: bold;
	min-height: 60px;
	@if($mode==big){
		font-size: 1.6em;
		min-height: 80px;
	}
	@if($mode==small)
	{
		min-height: 48px;
		font-size: 1.3em;
	}
	//@include sprite($buttons-next-hour-traffic-forecast-button);
	/*
	background-image: url(<?php echo $WT3_BaseUrl; ?>/buttons/30/nextHourTrafficForecastButton.png);
	background-repeat: no-repeat;
	background-position: 98%;	
	*/
	padding: 0 !important;
	//min-height: 55px;

	@include partialSpriteAfter($buttons-next-hour-traffic-forecast-button,-23,8);
	&:before{
		margin-top: 8px;
	}
}
.trafficforecast-group-list-item:hover
{
	cursor : pointer;
}
div.trafficforecast-highway-icon
{
	@include sprite($icons-highway-icon);
	
	/*
	background-image : url(<?php echo $WT3_BaseUrl; ?>/icons/50/highwayIcon.png);
	background-repeat : no-repeat;
	background-size: cover;
	width: 45px;
	height: 45px;
	*/
	display: table-cell;
	margin-top: 5px;
}
div.trafficforecast-group-description
{
	/*
	min-height: 45px;
	max-width: 240px;
	margin: 15px 0 0 10px;
	@if($mode==big){
		min-height: 60px;
		max-width: 420px;
		margin: 20px 0 0 10px;
	}
	@if($mode==small)
	{
		min-height: 40px;
		max-width: 170px;
		margin: 14px 0 0 10px;
	}
	display: table-cell;
	*/
	@if($mode==big){
		font-size: 1.5em;
	}
	display: table-cell;
	vertical-align: middle;
}


/* Traffic forecast chart */
h2.traffic-forecast-section-description
{
	width: 92%;
}
h2.traffic-forecast-section-description
h2.traffic-forecast-section-description.with-group-title
{
	margin: 0 auto;

}
span.traffic-forecast-to
{
	@include sprite($other-to);
	transform: translateY(5px);
	/*
	background-image: url(../other/50/to.png);
	background-repeat: no-repeat;
	background-position: 3px 1px;
	height: 18px;
	width: 16px;
	*/
	display: inline-block;
	margin: 0 2px;
}
h3.traffic-forecast-chart-title
{
	width: 92%;
	margin: 0px auto 0px auto;
	font-weight: 500;
	@if($mode==big){
		margin: 0px auto 15px auto;
	}
	@if($mode==small)
	{
		margin: 0px auto 10px auto;
	}
}
h3.traffic-forecast-chart-title.with-group-title
{
	margin: 0 auto;
	@if($mode==big){
		margin: 0 auto 15px auto;
		font-weight: 500;
	}
	@if($mode==small)
	{
		margin: 0 auto 30px auto;
	}
}
div.traffic-forecast-chart
{
	width: 92%;
	height: 300px;
	border-left: 1px $grey09 solid;
	border-bottom: 1px $grey09 solid;
	margin: 12px auto 0 auto;
	text-align: center;
	position: relative;
	@if($mode==big){
		height: 365px;
		margin: 0 auto;
	}
	@if($mode==small)
	{
		height: 200px;
		text-align: center;
		margin: 0 auto;
	}
}
@if($mode==big)
{
	div.traffic-forecast-chart.with-group-title
	{
		width: 90%;
		height: 330px;
		border-left: 1px $grey09 solid;
		border-bottom: 1px $grey09 solid;
		margin: 0 auto;
		text-align: center;
	}
	div.traffic-forecast-chart-bar.with-group-title
	{
		height: 244px;
	}
}
div.traffic-forecast-chart-bar
{
	width: 3%;
	height: 220px;
	margin: 54px 1% 0 0;
	display: inline-block;
	vertical-align: bottom;
	@if($mode==big)
	{
		height: 279px;
		margin: 50px 1% 0 0;
	}
	@if($mode==small){
		height: 160px;
		margin: 20px 1% auto auto;
	}
}
div.traffic-forecast-chart-traffic-axis-arrow
{
	@include sprite($other-chart-axis-vertical-arrow);
	transform: translate(-50%,-50%);
	/*
	background-image: url(../other/50/chartAxisVerticalArrow.png);
	position: relative;
	width: 15px;
	height: 15px;
	left: -8px;
	top: -8px;
	background-size: cover;
	*/
	top: 0;
	position: absolute;
	left: 0;
}
div.traffic-forecast-chart-time-axis-arrow
{
	@include sprite($other-chart-axis-horizontal-arrow);
	transform: translate(50%,50%);
	/*
	background-image: url(../other/50/chartAxisHorizontalArrow.png);
	position: relative;
	width: 15px;
	height: 15px;
	top: 278px;
	left: 98%;
	background-size: cover;
	*/
	bottom: 0;
	position: absolute;
	right: 0;
}
@if($mode==big){
	div.traffic-forecast-chart-time-axis-arrow.with-group-title
	{
		top: 304px;
	}
}
div.traffic-forecast-chart-traffic-axis-legend
{
	left: 13px;
	position: absolute;
	text-align: left;
	top: 2px;
	@if($mode==big){
		font-size: 1.2em;
	}
}
div.traffic-forecast-chart-time-axis-legend
{
	height: 30px;
	margin: 0 auto;
	@if($mode==big){
		font-size: 1.2em;
	}
	@if($mode==small){
		width: 100%;
		height: 20px;
	}
}
div.traffic-forecast-chart-hour
{
	width: 3%;
	height: 25px;
	padding-top: 5px;
	margin-right: 1%;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	@if($mode==big){
		height: 30px;
		padding-top: 5px;
		font-size: 1.1em;
	}
	@if($mode==small){
		height: 20px;
		font-size: 0.9em;
	}
}
@if($mode==big){
	div.traffic-forecast-chart-hour-day-legend
	{
		margin: 0 auto;
		text-align: center;
		font-size: 1.2em;
	}
}
div.traffic-forecast-chart-hour-day-legend
{
	margin: 10px auto 0 auto;
	text-align: center;
	@if($mode==small){
		margin: 6px auto 0 auto;
	}
}
div.traffic-forecast-chart-color-legend
{
	width: 92%;
	margin: 20px auto 0 auto;
	text-align: center;
	@if($mode==small){
		margin: 12px auto 0 auto;
	}
}
div.traffic-forecast-chart-color-legend-element
{
	display: inline-block;
	min-width: 12px;
	min-height: 12px;
	margin-right: 10px;
}
div.traffic-forecast-chart-color-legend-text
{
	display: inline-block;
	margin-right: 20px;
	@if($mode==big){
		font-size: 1.2em;
	}
	@if($mode==small){
		margin-right: 10px;
		font-size: 0.9em;
	}
}
.v
{
	background-color: $green02;
}
.o
{
	background-color: $salmon01;
}
.r
{
	background-color: $red03;
}


/* pikaday calendar */
.pika-single
{
	color: $blues02;
}
.pika-table th
{
	color: $blues02;
}
.pika-button
{
	color: $blues02;
}
.pika-button:hover
{
	color: $white;
	background-color: $blues02;
}
