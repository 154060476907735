//debug
$factor:0.5;
@if($mode==small){
	$factor: 0.5;
}

.weather.leaflet-speechbubble{
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.65);
	border-radius: 0;
}
.weatherForcast-icon-class{

	width: nth($icons-weather-00, 5)*($factor*2);

	height: nth($icons-weather-00, 6)*($factor*2);
	
	.weatherInfo div.icon{ //debug
		@include partialSpriteBefore($icons-weather-00)
	}
	&.weatherForcast--00 .weatherInfo div.icon{
		@include partialSpriteBefore($icons-weather-00)
	}
	&.weatherForcast--01 .weatherInfo div.icon{
		@include partialSpriteBefore($icons-weather-01)
	}
	&.weatherForcast--02 .weatherInfo div.icon{
		@include partialSpriteBefore($icons-weather-02)
	}
	&.weatherForcast--03 .weatherInfo div.icon{
		@include partialSpriteBefore($icons-weather-03)
	}
	&.weatherForcast--04 .weatherInfo div.icon{
		@include partialSpriteBefore($icons-weather-04)
	}
	&.weatherForcast--05 .weatherInfo div.icon{
		@include partialSpriteBefore($icons-weather-05)
	}
	&.weatherForcast--06 .weatherInfo div.icon{
		@include partialSpriteBefore($icons-weather-06)
	}
	&.weatherForcast--07 .weatherInfo div.icon{
		@include partialSpriteBefore($icons-weather-07)
	}
	&.weatherForcast--08 .weatherInfo div.icon{
		@include partialSpriteBefore($icons-weather-08)
	}
	&.weatherForcast--09 .weatherInfo div.icon{
		@include partialSpriteBefore($icons-weather-09)
	}
	&.weatherForcast--10 .weatherInfo div.icon{
		@include partialSpriteBefore($icons-weather-10)
	}
	&.weatherForcast--11 .weatherInfo div.icon{
		@include partialSpriteBefore($icons-weather-11)
	}
	&.weatherForcast--12 .weatherInfo div.icon{
		@include partialSpriteBefore($icons-weather-12)
	}
	.weatherInfo{
		
		color: $white ;
		position: absolute;
		div.icon{
			//padding: 0 !important;
			transform: translate(0%,0%);
			width: nth($icons-weather-00, 5);
			position: absolute;
			height: nth($icons-weather-00, 6);
			display: inline-block;
			z-index: 2;
		}
		div.text{
			
			line-height: nth($icons-weather-00, 6)*$factor;
			height: nth($icons-weather-00, 6)*$factor;
			vertical-align: 	middle;
			font-size: 	(nth($icons-weather-00, 6)*$factor)-2;
			font-weight: 700;
			margin-left: 35%;
			display: inline-block;
			box-shadow: 5px 5px 20px 3px rgba(0,0,0,0.45);
			box-shadow:5px 5px 10px 1px rgba(0, 0, 0, 0.45);
			@if($mode==small){
				box-shadow:2px 2px 5px 1px rgba(0, 0, 0, 0.45);
			}
			@if($mode==big){
				box-shadow: 5px 5px 20px 3px rgba(0,0,0,0.45);
			}
			background: $blues02;
			padding: 0 0 0 nth($icons-weather-00, 6)*$factor;
			transform: translate(0%,50%);
			.tempMin{
				display: none;
			}
			.wind{
				display: none;
			}
		}
	}
}
.weather .speechTitle{

}
/*
.weather .speechTitle{
	position: absolute;
	height: 30px;
	background: $blues03;
	color: $white;
	font-size: 25px;
	line-height: 30px;
	font-weight: bold;
	left: 5px;
	right: 5px;
	top: 5px;
	text-align: center;
}*/
.weather .speechContent{
	top: 5px;
	bottom: 5px;
	left: 5px;
	right: 5px;
	position: absolute;
	text-align: center;
}

.leaflet-popin-center.weather{
	height: 400px;
	@if($mode==small){
		height: 300px;
	}
	.leaflet-popin-title{
		background: $black;
			color: $white;
		span.leaflet-popin-title{
			
			.day{
				font-size: 14px;
				@if($mode==small){
					font-size: 10px;
				}
			}
		}
	}
	.leaflet-popin-content{
		height: auto;
		table{
			width: 100%;
			border-spacing: 0;
			color: $grey08;
			tr.slot{
				/*color: $grey07;*/
				height: 100px;
				@if($mode==small){
					height: 73px;
				}
				td{
					border-bottom: 1px solid $grey04;
					vertical-align: middle;
				}
				td.name{
					display: none;
				}
				td.time{
					text-align: center;
					span.day{
						font-size: 20px;
						display: block;
						font-weight: 500;
						color: $blues03;
					}
					
					span.time{

						font-size: 40px;
						margin-top: -10px;
						@if($mode==small){
							font-size: 30px;
						
						}
						display: block;
						font-weight: 500;
					}
				}
				td.temp{
					font-size: 40px;
					font-weight: bold;
				}
				&.future.slot--4{
					border: none;
					td{
						border: none;
					}
				}
				&.future.slot--5,
				&.future.slot--6,
				&.future.slot--7,
				&.future.slot--8,
				&.future.slot--9,
				&.future.slot--10,
				&.future.slot--11,
				&.future.slot--12{
					display: none;
				}
			}

		}
	}
}

.weather
{
	.leaflet-speechbubble-content{
		
		cursor: pointer;
		overflow: hidden;
		font-size: 20px;
		@if($mode==small){
			font-size: 14px;
		}
		.speechContent{
			height: 100px;
			.nearestWheaterPointInfo{
				font-weight: 100;
				font-size: 16px;
				color: $blues03;
			}
			div.name{
				display: block;
				color: $blues03;
				
				font-weight: bold;
				@if($mode==small){
					font-size: 20px;
				}
			}
			table{
				width: 100%;
				border-spacing : 0;
				border-collapse : collapse;
				border: 0;
				
				tr.slot{
					display: block;
					text-align: center;
					height: 100px;
					@if($mode==small){
						height: 50px;
					}
					color:$grey07;
					&.future{
						display: none;
					}
					td{
						padding:0;
						width: 30%;
						text-align: center;
						display: inline-block;
					}
					td.time{
						font-weight: bold;
							display: none;
							
						
					}
					td.weatherPic{
						@if($mode==big){
							margin-left: -25px;
							margin-right: 25px;
						}
						.weatherForcast-icon-class{
							margin: auto;
						}
					}
					td.temp{
						font-size: 40px;
						font-weight: bold;
						@if($mode==small){
							font-size: 30px;
						}
					}
					
					th.day{
						background: $grey07;
						color: $white;
						font-weight: bold;
						display: none;
					}
				}
				
				tr.empty{
					td{
						height: 20px;
					}
				}
			}
		}
	}
	.weatherForcast-icon-class{

		width: nth($icons-weather-00, 5);

		height: nth($icons-weather-00, 6);

		&.weatherForcast--00{
			@include sprite($icons-weather-00);
		}
		&.weatherForcast--01{
			@include sprite($icons-weather-01);
		}
		&.weatherForcast--02{
			@include sprite($icons-weather-02);
		}
		&.weatherForcast--03{
			@include sprite($icons-weather-03);
		}
		&.weatherForcast--04{
			@include sprite($icons-weather-04);
		}
		&.weatherForcast--05{
			@include sprite($icons-weather-05);
		}
		&.weatherForcast--06{
			@include sprite($icons-weather-06);
		}
		&.weatherForcast--07{
			@include sprite($icons-weather-07);
		}
		&.weatherForcast--08{
			@include sprite($icons-weather-08);
		}
		&.weatherForcast--09{
			@include sprite($icons-weather-09);
		}
		&.weatherForcast--10{
			@include sprite($icons-weather-10);
		}
		&.weatherForcast--11{
			@include sprite($icons-weather-11);
		}
		&.weatherForcast--12{
			@include sprite($icons-weather-12);
		}
	}
}
/*
	#weatherBanner{

		height: 60px;
		width: 100%;
		background: rgba(255,255,255,.8);
		z-index: 500;
		position: absolute;
		font-size: 20px;
		top: 0;
		left: 0;
	padding-left: 440px; //oki
	@if($mode==big){
		padding-left: 500px;
		height: 110px;
		font-size: 40px;
	}
	@if($mode==small){
		top: 45px;
		padding-left: 0;
		height: auto;
	}
	div{
		display: inline-block;
		margin: 0 5px;
		vertical-align: middle;

	}
	.weatherForcast-icon-class{
		width: nth($icons-weather-00, 5);

		height: nth($icons-weather-00, 6);
		display: inline-block;
	}
	.title{
		@if($mode==small){
			max-width: 30%;
		}
	}
	.time,
	.temp{



		//font-size: 20px;
		font-weight: bold;

	}
	.time{
		//font-size: 20px;
		min-width: 70px;
		@if($mode==small){
			min-width: 0px;
			max-width: 20%;
		}

	}
	&.future:first-child .time{
		font-size: 1.25em;

		color: $blues02;
	}
	.weatherForcast-icon-class{
		width: simpleMap(weatherPopup-ul--li--height);
		height: simpleMap(weatherPopup-ul--li--height);


	}
	.weatherForcast-icon-class{

		width: nth($icons-weather-00, 5);

		height: nth($icons-weather-00, 6);

		&.weatherForcast--00{
			@include sprite($icons-weather-00);
		}
		&.weatherForcast--01{
			@include sprite($icons-weather-01);
		}
		&.weatherForcast--02{
			@include sprite($icons-weather-02);
		}
		&.weatherForcast--03{
			@include sprite($icons-weather-03);
		}
		&.weatherForcast--04{
			@include sprite($icons-weather-04);
		}
		&.weatherForcast--05{
			@include sprite($icons-weather-05);
		}
		&.weatherForcast--06{
			@include sprite($icons-weather-06);
		}
		&.weatherForcast--07{
			@include sprite($icons-weather-07);
		}
		&.weatherForcast--08{
			@include sprite($icons-weather-08);
		}
		&.weatherForcast--09{
			@include sprite($icons-weather-09);
		}
		&.weatherForcast--10{
			@include sprite($icons-weather-10);
		}
		&.weatherForcast--11{
			@include sprite($icons-weather-11);
		}
		&.weatherForcast--12{
			@include sprite($icons-weather-12);
		}
	}
}
*/