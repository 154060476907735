.retentionarea-icon-class
{
    @include sprite($icons-retention-area-time);
    
}
.tollwaitingtime-icon-class
{
    @include sprite($icons-toll-waiting-time);
  
}
.exit-icon-class
{
    @include sprite($icons-exit);
   
}
.toll-icon-class
{
    @include sprite($icons-toll);
}
.servicearea-icon-class
{
    @include sprite($icons-servicearea);
  
}
.restarea-icon-class
{
    @include sprite($icons-restarea);
   
}
.userlocation-icon-class
{
     @include sprite($icons-user-location-marker); 
}

/* Traffic Alert */
div.trafficalert-notification-1{    z-index: 9998; @include sprite($buttons-traffic-alert-button-1); }
div.trafficalert-notification-2{    z-index: 9998; @include sprite($buttons-traffic-alert-button-2); }
div.trafficalert-notification-3{    z-index: 9998; @include sprite($buttons-traffic-alert-button-3); }
div.trafficalert-notification-4{    z-index: 9998; @include sprite($buttons-traffic-alert-button-4); }
div.trafficalert-notification-5{    z-index: 9998; @include sprite($buttons-traffic-alert-button-5); }
div.trafficalert-notification-6{    z-index: 9998; @include sprite($buttons-traffic-alert-button-6); }
div.trafficalert-notification-7{    z-index: 9998; @include sprite($buttons-traffic-alert-button-7); }
div.trafficalert-notification-8{    z-index: 9998; @include sprite($buttons-traffic-alert-button-8); }
div.trafficalert-notification-9{    z-index: 9998; @include sprite($buttons-traffic-alert-button-9); }
div.trafficalert-notification-10{   z-index: 9998; @include sprite($buttons-traffic-alert-button-9p); }

div.leaflet-popin-title.trafficalert
{
    background-color: $red01;
}
li.trafficalert-list-item
{
    text-decoration: none;
    text-align: justify;
    margin: 0 auto;
    width: 90%;
    overflow-x: hidden;
}
p.trafficalert-list-item-content
{
    font-family: $font03;
    font-size:  simpleMap(trafficalert-list-item-content--font-size);
}
h2.trafficalert-list-item-title
{
    font-weight: bold;
    font-size:  simpleMap(trafficalert-list-item-title--font-size);
    color:$red01;
}
