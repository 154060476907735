
/* SideBar */
nav.leaflet-sidebar
{
	width: simpleMap(leaflet-sidebar-item--width);
	height: 100%;
	background-color: $grey01;
	color: $grey06;
	margin: 0;
	z-index: 10001;
	padding: 0;
	opacity: 1;	
    font-weight: 700;
    font-size: 16px;
    @if($mode==big)
    {
        font-size: 20px ;
        
    }
    @if($mode==small)
    {
        font-size: 14px;
        
    }
    z-index: 10001;
}
nav.leaflet-sidebar div.menu
{

    @include partialSpriteBefore($buttons-menu-hamburger);
    height: nth($buttons-menu-hamburger, 6);
    line-height: nth($buttons-menu-hamburger, 6);
    vertical-align: middle;
    cursor: pointer;
    color: $blues04;
    background-color: $white;
}
nav.leaflet-sidebar ul
{
	width:100%;
    list-style: none;
    padding: 0;
    margin: 0;
}
li.leaflet-sidebar-item
{
    height: simpleMap(leaflet-sidebar-item--height);
    width:100%;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid $grey04;
    a{
        display: block;
        overflow: hidden;
        text-decoration: none;
        font: inherit;

        div{
             height: simpleMap(leaflet-sidebar-item--height);
            padding: 0;
           
            line-height:   simpleMap(leaflet-sidebar-item--height);
            margin: 0;
            color: $grey06;
        }
    }
}
li.leaflet-sidebar-item.real-time
{
    .leaflet-sidebar-item{

        @include partialSpriteBefore($icons-icon-menu-real-time-traffic);
    }

}
li.leaflet-sidebar-item.webcams
{
   .leaflet-sidebar-item{

     @include partialSpriteBefore($icons-icon-menu-webcams);
 }

}
li.leaflet-sidebar-item.trip-times
{
    .leaflet-sidebar-item{

        @include partialSpriteBefore($icons-icon-menu-trip-times);
    }

}
li.leaflet-sidebar-item.traffic-forecast
{
    .leaflet-sidebar-item{

        @include partialSpriteBefore($icons-icon-menu-traffic-forecast);
    }

}

li.leaflet-sidebar-item.weather-forecast
{
    .leaflet-sidebar-item{

        @include partialSpriteBefore($icons-icon-menu-weather-forecast);
    }

}



