/* Menu Bar */
div.leaflet-menu-bar
{
	height: simpleMap(leaflet-menu-bar--height);
	background-color: $white;
	color: $blues02;
	font-family: $font01;
	top: 14px;
	left: 10px;
	@if($mode==small){
		top: 18px;
		left: 14px;
	}
	@if($mode==small)
	{
		top:0;
		left: 0;
		width: 100%;
		background-color: rgba(255,255,255,0.85);
		border: none;
		box-shadow: none;
	}
}
div.leaflet-menu-bar-element
{
	display: inline-block;
	vertical-align: middle;
	padding: 0px;
	margin: 1px 0 0 6px;
	@if($mode==small){
		padding:0 ;
		margin: 0
	}
}
div.leaflet-menu-bar-element.about:hover
{
	cursor: pointer;
}


.leaflet-bar{
	/**** pour debug ***
	&::before{
		position: fixed;
		bottom: 0;
		left: 0;
		color: white;
		font-weight: bold;
		background: deeppink;
		content: "Md";
		@if($mode==small){
			content: "Xs";
		}
		@if($mode==big){
			content: "XL";
		}

	}*/
	div.burger-menu
	{
		@include sprite($buttons-menu-hamburger);
		a.burger-menu,
		a.burger-menu:hover
		{
			
			padding: 0;
			margin: 0;
			background: none;
			cursor: pointer;
		}

	}
	div.about
	{
		@include sprite($buttons-button-about);

		a.about,
		a.about:hover
		{

			padding: 0;
			margin: 0;
			background: none;
			cursor: pointer;
		}
	}

}

div.leaflet-menu-bar-element.about
{
	//width: 58px;
	text-align: center;
	@if($mode==small){
		float: right;
	}
}
div.leaflet-menu-bar-element.about span
{
	color: $white;
	background-color: $blues02;
	border: 1px solid $blues02;
	display: block;
	border-radius: 50px;
	width: 36px;
	height: 36px;
	font-family: $font02;
	margin: 0 auto;
	font-size: 24px;
}
div.leaflet-menu-bar-element.general-info
{
	display: inline-block;
	width: 278px;
	padding: 5px 0 0 15px;
	vertical-align: top;
	@if($mode==small){
		padding: 0px 0 0 10px;
		width: auto;
		    margin: 5px 0 0 0;
	}
	@if($mode==big){
		width: 364px;
	}
}
.general-info-title
{
	font-size: 22px;
	margin: 0;
	height: 29px;
	@if($mode==small){
		font-size: 17px;
		height: 19px;
	}
	@if($mode==big){
		font-size: 32px;
		height: 42px;
	}
}
.general-info-subtitle
{
	font-size: 12px;
	@if($mode==big){
		font-size: 15px;
	}
	@if($mode==small){
		font-size: 11px;
		height: 8px;
	}
	margin: 0;
}
div.leaflet-popin-content.about-popin
{
	width: 90%;
	margin: 0 auto;
}

