


@function  simpleMap($propety,$locmode:$mode) {
	@if( map-has-key($simpleMapVars,$propety) )
	{
		@return map-get(map-get($simpleMapVars,$propety),$locmode);
	}
}
/*
@mixin partialSpriteBefore($sprite) {

  @include sprite-image($sprite);
  @include sprite-position($sprite);
  background-size:  nth($sprite, 5) nth($sprite, 6);
 
}
*/

@mixin partialSpriteBefore($sprite,$marge:6px,$margeTop:0) {
  &:before{
  	 @include sprite($sprite);
  	 content: " ";
  	 float: left;
  	 margin-left:    - nth($sprite, 5) - $marge;
  	  margin-top:    $margeTop;
  }
  padding-left: nth($sprite, 5) + $marge;
 
}

@mixin partialSpriteAfter($sprite,$marge:6px,$margeTop:0) {
  &:before{
  	 @include sprite($sprite);
  	 content: " ";
  	 float: right;
  	 margin-right:    - nth($sprite, 5) - $marge;
  	 margin-top:    $margeTop;
  }
  padding-right: nth($sprite, 5) + $marge;
 
}