
$theme:leaflet-style;
.leaflet-container,
.leaflet-container *{
	box-sizing: border-box;
}
/* Override leaflet style */
.leaflet-top {
	top: simpleMap(leaflet-top--top);
	.leaflet-control {
		margin-top: simpleMap(leaflet-top--margin-top);
		@if($mode==big)
		{
			margin-left: 14px;
		}
	}

}
.leaflet-bar,
.leaflet-touch .leaflet-bar  {
	
	box-shadow: simpleMap(leaflet-bar--box-shadow);
	border-radius: 0;
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	border: none;
}
.leaflet-bar a,
.leaflet-touch .leaflet-bar a,
{
	width: simpleMap(leaflet-bar-a--width);
	height: simpleMap(leaflet-bar-a--height);
	
	&:hover {
		background-color: $blues01;
	}
	&:first-child {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
	&:last-child {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border-bottom: none;
	}
}

.leaflet-popup-content-wrapper
{
	border-radius: 0;
}
.leaflet-container a.leaflet-active
{
	outline: none;
}

