/* zoom in / zoom ou t customization */
a.leaflet-control-zoom-in:hover,
a.leaflet-control-zoom-in {
	@include sprite($buttons-zoom-in-button-activated);
}
a.leaflet-control-zoom-in.leaflet-disabled:hover,
a.leaflet-control-zoom-in.leaflet-disabled {
	@include sprite($buttons-zoom-in-button);

}
a.leaflet-control-zoom-out:hover,
a.leaflet-control-zoom-out {
	@include sprite($buttons-zoom-out-button-activated);

}
a.leaflet-control-zoom-out.leaflet-disabled:hover,
a.leaflet-control-zoom-out.leaflet-disabled {
	@include sprite($buttons-zoom-out-button);

}


/* Custom controls */

.leaflet-bar.leaflet-customButtonBar,
.leaflet-bar.leaflet-customButtonBar{
	a {
	
		width:  simpleMap(leaflet-customButtonBar--a--size);
		height:  simpleMap(leaflet-customButtonBar--a--size);
		line-height:  simpleMap(leaflet-customButtonBar--a--size);
		text-decoration: none;
		text-align: center;
	
		&.leaflet-customButtonBar-information-fr,
		&.leaflet-touch .leaflet-customButtonBar-information-fr
		{
			@include sprite($buttons-information-button-fr);

		}
		&.leaflet-customButtonBar-information-activated-fr,
		&.leaflet-touch .leaflet-customButtonBar-information-activated-fr
		{
			@include sprite($buttons-information-button-activated-fr);
			background-color: $blues02;

		}
		&.leaflet-customButtonBar-information-en,
		&.leaflet-touch .leaflet-customButtonBar-information-en
		{
			@include sprite($buttons-information-button-en);
		}
		&.leaflet-customButtonBar-information-activated-en,
		&.leaflet-touch .leaflet-customButtonBar-information-activated-en
		{
			@include sprite($buttons-information-button-activated-en);
			background-color: $blues02;
		}
		&.leaflet-customButtonBar-disruptions-fr,
		&.leaflet-touch .leaflet-customButtonBar-disruptions-fr
		{
			@include sprite($buttons-disruption-button-fr);

		}
		&.leaflet-customButtonBar-disruptions-activated-fr,
		&.leaflet-touch .leaflet-customButtonBar-disruptions-activated-fr
		{
			@include sprite($buttons-disruption-button-activated-fr);
			background-color: $blues02;
		}
		&.leaflet-customButtonBar-disruptions-en,
		&.leaflet-touch .leaflet-customButtonBar-disruptions-en
		{
			@include sprite($buttons-disruption-button-en);
		}
		&.leaflet-customButtonBar-disruptions-activated-en,
		&.leaflet-touch .leaflet-customButtonBar-disruptions-activated-en
		{
			@include sprite($buttons-disruption-button-activated-en);
			background-color: $blues02;
		}
		&.leaflet-customButtonBar-exits-fr,
		&.leaflet-touch .leaflet-customButtonBar-exits-fr
		{
			@include sprite($buttons-exit-button-fr);
		}
		&.leaflet-customButtonBar-exits-activated-fr,
		&.leaflet-touch .leaflet-customButtonBar-exits-activated-fr
		{
			@include sprite($buttons-exit-button-activated-fr);
			background-color: $blues02;
		}
		&.leaflet-customButtonBar-exits-en,
		&.leaflet-touch .leaflet-customButtonBar-exits-en
		{
			@include sprite($buttons-exit-button-en);
		}
		&.leaflet-customButtonBar-exits-activated-en,
		&.leaflet-touch .leaflet-customButtonBar-exits-activated-en
		{
			@include sprite($buttons-exit-button-activated-en);
			background-color: $blues02;
		}
		&.leaflet-customButtonBar-webcams-fr,
		&.leaflet-touch .leaflet-customButtonBar-webcams-fr
		{
			@include sprite($buttons-webcam-button-fr-en);

		}
		&.leaflet-customButtonBar-webcams-activated-fr,
		&.leaflet-touch .leaflet-customButtonBar-webcams-activated-fr
		{
			@include sprite($buttons-webcam-button-activated-fr-en);
			background-color: $blues02;

		}
		&.leaflet-customButtonBar-webcams-en,
		&.leaflet-touch .leaflet-customButtonBar-webcams-en
		{
			@include sprite($buttons-webcam-button-fr-en);
		}
		&.leaflet-customButtonBar-webcams-activated-en,
		&.leaflet-touch .leaflet-customButtonBar-webcams-activated-en
		{
			@include sprite($buttons-webcam-button-activated-fr-en);
			background-color: $blues02;

		}
		&.leaflet-customButtonBar-areas-fr,
		&.leaflet-touch .leaflet-customButtonBar-areas-fr
		{
			@include sprite($buttons-area-button-fr);

		}
		&.leaflet-customButtonBar-areas-activated-fr,
		&.leaflet-touch .leaflet-customButtonBar-areas-activated-fr
		{
			@include sprite($buttons-area-button-activated-fr);
			background-color: $blues02;

		}
		&.leaflet-customButtonBar-areas-en,
		&.leaflet-touch .leaflet-customButtonBar-areas-en
		{
			@include sprite($buttons-area-button-en);
		}
		&.leaflet-customButtonBar-areas-activated-en,
		&.leaflet-touch .leaflet-customButtonBar-areas-activated-en
		{
			@include sprite($buttons-area-button-activated-en);
			background-color: $blues02;
		}
		&.leaflet-customButtonBar-forecasts-fr,
		&.leaflet-touch .leaflet-customButtonBar-forecasts-fr
		{
			@include sprite($buttons-forecast-button-fr);

		}
		&.leaflet-customButtonBar-forecasts-activated-fr,
		&.leaflet-touch .leaflet-customButtonBar-forecasts-activated-fr
		{
			@include sprite($buttons-forecast-button-activated-fr);
			background-color: $blues02;
		}
		&.leaflet-customButtonBar-forecasts-en,
		&.leaflet-touch .leaflet-customButtonBar-forecasts-en
		{
			@include sprite($buttons-forecast-button-en);
		}
		&.leaflet-customButtonBar-forecasts-activated-en,
		&.leaflet-touch .leaflet-customButtonBar-forecasts-activated-en
		{
			@include sprite($buttons-forecast-button-activated-en);
			background-color: $blues02;
		}
		&.leaflet-customButtonBar-userlocation,
		&.leaflet-touch .leaflet-customButtonBar-userlocation
		{
			@include sprite($buttons-user-location-button);

		}
		&.leaflet-customButtonBar-userlocation-activated,
		&.leaflet-touch .leaflet-customButtonBar-userlocation-activated
		{
			@include sprite($buttons-user-location-button);
		}
		&.leaflet-customButtonBar-triptimelist,
		&.leaflet-touch .leaflet-customButtonBar-triptimelist
		{
			@include sprite($buttons-trip-time-search-button);

		}
		&.leaflet-customButtonBar-triptimelist-activated,
		&.leaflet-touch .leaflet-customButtonBar-triptimelist-activated
		{
			@include sprite($buttons-trip-time-search-button);
		}
		&.leaflet-customButtonBar-fullscreen,
		&.leaflet-touch .leaflet-customButtonBar-fullscreen
		{
			@include sprite($buttons-fullscreen-go-button);

		}
		&.leaflet-customButtonBar-exitfullscreen,
		&.leaflet-touch .leaflet-customButtonBar-exitfullscreen
		{
			@include sprite($buttons-fullscreen-back-button); 
		}
	}
}

a.leaflet-bar-part.leaflet-customButtonBar-areas-activated-fr,
a.leaflet-bar-part.leaflet-customButtonBar-webcams-activated-fr,
a.leaflet-bar-part.leaflet-customButtonBar-exits-activated-fr,
a.leaflet-bar-part.leaflet-customButtonBar-information-activated-fr,
a.leaflet-bar-part.leaflet-customButtonBar-disruptions-activated-fr,
a.leaflet-bar-part.leaflet-customButtonBar-forecasts-activated-fr,
a.leaflet-bar-part.leaflet-customButtonBar-areas-activated-en,
a.leaflet-bar-part.leaflet-customButtonBar-webcams-activated-en,
a.leaflet-bar-part.leaflet-customButtonBar-exits-activated-en,
a.leaflet-bar-part.leaflet-customButtonBar-information-activated-en,
a.leaflet-bar-part.leaflet-customButtonBar-disruptions-activated-en,
a.leaflet-bar-part.leaflet-customButtonBar-forecasts-activated-en
{
	background-color: $blues02;
}




/* Hidden, blur & with-control-bottom*/
.hidden
{
	visibility: hidden;
	opacity: 0;
	width: 0!important;
	height: 0 !important;
	padding: 0!important;
	margin: 0!important;
	border: none !important;
	display: none !important;
}
.blur
{
	z-index: 9999;
	opacity: 1;
}
.with-control-bottom
{

}

@if($mode==small){
	.leaflet-bottom {
		width: 100%;
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		z-index: 1000;
		display: flex;
		flex-direction: column;
		.leaflet-bar.horizontal {
			float: none;
			margin: 0;
			height: 60px;
			display: flex;
			order: 2;
			flex-direction: row;
			.leaflet-bar-part {
				float: none;
				display: inline-block;
				margin: 0;
				position: relative;
				top: 0;
				&.leaflet-customButtonBar-forecastlist,
				.leaflet-customButtonBar-forecastdateselectscreen{
					min-width: 60px;
				}
				&.leaflet-customButtonBar-forecasttimeslide{
					
					border-right: 1px solid $grey03 ! important;
				}
				> div {
					position:absolute;
					height: 60px;
					left: 0;
					right: 0;
					bottom: 0;
					top: 0;
				}
			}
		}
		.leaflet-control-attribution.leaflet-control{
			   order: 1;
			    &:first-child{
			    	margin: 0;
			    }
		}

	}
}

