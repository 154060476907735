.webcam-icon-class{     @include sprite($icons-webcam-marker)       }
.webcam-icon-class-2{   @include sprite($icons-webcam-marker2)      }
.webcam-icon-class-3{   @include sprite($icons-webcam-marker3)      }
.webcam-icon-class-4{   @include sprite($icons-webcam-marker4)      }
.webcam-icon-class-5{   @include sprite($icons-webcam-marker5)      }
.webcam-icon-class-6{   @include sprite($icons-webcam-marker6)      }
.webcam-icon-class-7{   @include sprite($icons-webcam-marker7)      }
.webcam-icon-class-8{   @include sprite($icons-webcam-marker8)      }
.webcam-icon-class-9{   @include sprite($icons-webcam-marker9)      }
.webcam-icon-class-9p{  @include sprite($icons-webcam-marker9p)     }
.webcam-icon-class-10{  @include sprite($icons-webcam-marker9p)     }




/* webcam */
div.leaflet-popin-title.webcam
{
    background-color: $purple01;
}
li.webcam
{
    min-height: simpleMap(webcam-thumbnail--height);
    padding: 12px 0 8px 0 !important;
}
div.webcam-thumbnail
{
    width: simpleMap(webcam-thumbnail--width);
    height:simpleMap(webcam-thumbnail--height);
    display: inline-block;
    position: relative;
    cursor: pointer;
    line-height: 0;
    
}
div.webcam-thumbnail.disabled
{
    @include sprite($other-no-webcam);
    width: simpleMap(webcam-thumbnail--width);
    height:simpleMap(webcam-thumbnail--height);
   
     display: inline-block;
    position: relative;
    cursor: pointer;
    line-height: 0;
}
img.webcam-thumbnail
{
    width: simpleMap(webcam-thumbnail--width);
    height: simpleMap(webcam-thumbnail--height);
    z-Index: 1;
    top: 0;
    left: 0;
    position: absolute;
}
p.webcam-overlay
{
    @include sprite($icons-play-webcam-overlay);
    /*
    width: simpleMap(webcam-thumbnail--width);
    height: simpleMap(webcam-thumbnail--height);
    background: url('../icons/50/playWebcamOverlay.png');
    background-size: cover;*/
    z-Index: 2;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
}
p.webcam-description
{
    @if($mode==standard)
    {

        width: 170px;
        margin: 0 0 0 10px;
        padding: 0;
        display: inline-block;
        font-size: 1.25em;
    }
    @if($mode==big)
    {
        width: 300px;
        margin: 0 0 0 20px;
        padding: 0;
        display: inline-block;
        font-size: 1.4em;
    }
    @if($mode==small)
    {
        width: 45%;
        min-height: 30px;
        margin: 0;
        padding: 0 0 0 10px;
        display: inline-block;  
        font-size: 1em;
    }
    vertical-align: top;
    cursor: pointer;
}
p.webcam-description.disabled
{
    cursor: auto;
    color: $grey05;
}
span.webcam-count
{
    font-size: 0.8em;
    font-weight: normal;
}
.leaflet-popin-content div.webcam-player
{
    padding-top: 15px;
    overflow-y: hidden;
}
.leaflet-popin-content div.webcam-player>h2
{
    width: simpleMap(webcam-player--width);
    //max-Width: 95%;
    line-height: simpleMap(webcam-player--line-height);
    @if($mode==small)
    {
        
        text-align: justify;
    }
}
.leaflet-popin-content video
{
    position:relative;
    display: block;
    width:simpleMap(webcam-player--width);
    height:simpleMap(webcam-player--height);
    margin : 0 auto 30px auto;
    border: none;
    background-color : $black;
    //max-Width: 95%;
}
.leaflet-popin-content div.webcam-time
{
    margin : 30px auto 0 auto;
    @if($mode==small)
    {
        margin : 10px auto 0 auto;
    }
    padding : 0 3px 0 0;
    width:simpleMap(webcam-player--width);
    text-align : right;
    font-size: 1.2em;
    color: $white;
    background-color: $black;
    //max-Width: 94%;
}
.leaflet-popin-content div.webcam-error
{
    margin : 30px auto auto auto;
    @if($mode==small)
    {
        margin : 10px auto auto auto;
    }
    text-align : center;
    font-size: 1.5em;
}