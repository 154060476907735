/*************TODO VVV ******************/
/* Popin */
div.leaflet-popin
{
	position:absolute;
	margin:0;
	right:0;
	left:0;
	top:0;
	bottom:0;
	width:100%;	
	background-color:rgba(15,15,15,0.5);
	z-index:9999;
	font-family: $font01;
}
div.leaflet-popin-center
{
	margin: auto;
	position: absolute;
	top: 0; bottom: 0; left: 0; right: 0;		
	width: 420px;
	height: 80%;
	border: none;
	background-color:$white;
	@if($mode==big){
		width: 600px;
	}
	@if($mode==small){
		min-width: 280px;		
		max-width: 380px;
		width: 90%;
		height: 85%;
	}
}
div.leaflet-popin-title
{
	position: absolute;
	top: -36px;
	left: 0;
	color: $white;
	background-color: $blues02;
	font-size: 1.45em;
	/*font-weight: 700;*/
	width:420px;
	height: 36px;
	padding: 3px;
	@if($mode==big){
		top: -45px;
		margin : auto;
		font-size: 2.1em;
		width:600px;
		height: 45px;
	}
	@if($mode==small){
		top: -26px;
		font-size: 15px;
		width: 100%;
		max-width: 380px;
		height: 26px;
		padding: 1px 0 1px 0;
	}
}
span.leaflet-popin-title
{	
	width: 100%;
	margin-left: 6px;
}
span.leaflet-popin-close
{
	position: absolute;
	right: 0;
	margin: 1px 2px 0 0;
	margin: 0;
	transform: scale(0.775);	
	@include sprite($buttons-button-close-popin);
	/*
	width: 25px;
	height: 25px;
	background-image: url(<?php echo $WT3_BaseUrl; ?>/buttons/50/buttonClosePopin.png);
	background-size: cover;
	*/
}
span.leaflet-popin-close:hover
{
	cursor: pointer;
}
div.leaflet-popin-content
{	
	margin: 0;
	padding : 0;
	width:100%;
	height: 100%;
	overflow-x:hidden;
	overflow-y:auto;
	-webkit-overflow-scrolling: touch;
}
div.leaflet-popin-content div.blur
{	
	width: 100%;
	height: simpleMap(leaflet-popin-content-blur--height);
	position: absolute;
	
	
	margin: 0 auto;
	padding: 0;
	
	
	width:420px;
	
	@if($mode==big){
		
		width:600px;
	
	}
	@if($mode==small){
		
		width: 100%;
		max-width: 380px;
	
	}
	&.top{
		top: 0;
		background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
	}
	&.bottom{
		bottom:0; ;
		background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
	}

}

.leaflet-popin-content h2
{
	margin: 5px auto 5px auto;	
	@if($mode==big){
		margin: 15px auto 15px auto;
	}
	@if($mode==small){
		margin: 3px auto 3px auto;
	}
}
.leaflet-popin-content ul
{
	margin: 0;
	padding: 0;
	list-style: none;
}
.leaflet-popin-content ul li
{
	display: block;
	border-bottom: 1px solid $grey02;
	padding: 15px 0 15px 0;
	width: 92%;
	margin: 0 auto;
	@if($mode==small){
		padding: 10px 0 10px 0
	}
}
.leaflet-popin-content ul li:last-child
{
	border-bottom: none;
}
.leaflet-popin-content h2.update-error
{
	width: 92%;
	margin-left: auto;
	margin-right: auto;
	@if($mode==standard){
		font-size: 1.45em;
	}
	@if($mode==big){
		font-size: 1.6em;
	}
}
.leaflet-popin-content div.update-error
{
	width: 92%;
	margin: 0 auto;
	font-size: 1.2em;
}
div.back-to-list
{
	margin: 15px 0 15px 15px;
//	padding-left: 40px;
	//height: 25px;
	@include partialSpriteBefore($icons-icon-back);
	/*
	background-image: url(../icons/50/iconBack.png);
	background-repeat: no-repeat;*/
}
a.back-to-list
{
	text-decoration: none;
	@if($mode==standard){
		font-size: 14px;
	}
}

