
/* About pop-in */
#aboutTitle
{
	font-size: 18px;
	font-weight: bold;
	margin-top: 15px;
	margin-bottom: 0;
	padding: 0;
}
#aboutVersion
{
	font-size: 12px;
	margin-top: 0;
	margin-bottom: 10px;
	padding: 0;
}
div.aboutTable
{
	display: table;
	border-collapse: separate;
    border-spacing: 5px 0;
}
div.aboutTableRow
{
	display: table-row;
}
div.aboutTableCell
{
	display: table-cell;
	vertical-align: top;
	font-size: 13px;
}
img.aboutLogo
{
	width:80px;
	height:40px;
}
