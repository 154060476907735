
/* Popup content */
div.leaflet-popup-content
{
	margin: 4px 23px 2px 4px;
	max-width: 280px;
	max-height: 200px;
	overflow-y:auto;
}
div.leaflet-popup-content div
{
	margin: 2px;
	min-width:120px;
	min-height:32px;
	background-repeat: no-repeat;
	font-family: $font03;
	font-size: 15px;
}
@if ($mode==standard or $mode==big)
{
	$offset:0;
	@if ($mode==standard)
	{
		$offset:6;
	}
	@if ($mode==big)
	{
		$offset:3;
	}

	div.leaflet-popup-content div.toll
	{
		@include partialSpriteBefore($symbols-toll,$offset);
		
	}
	div.leaflet-popup-content div.exit
	{
		@include partialSpriteBefore($symbols-exit,$offset);
		
	}
	div.leaflet-popup-content div.junction
	{
		@include partialSpriteBefore($symbols-junction,$offset);
		
		
	}

	div.leaflet-popup-content div.information
	{
		@include partialSpriteBefore($symbols-information,$offset);
		

	}
	div.leaflet-popup-content div.retentionarea
	{
		@include partialSpriteBefore($icons-retention-area-time,$offset);
		
		
	}
	div.leaflet-popup-content div.tollwaitingtime
	{
		@include partialSpriteBefore($icons-toll-waiting-time,$offset);
		

	}
	div.leaflet-popup-content div.roadworks
	{
		@include partialSpriteBefore($symbols-roadworks,$offset);
		

	}
	div.leaflet-popup-content div.incident
	{
		@include partialSpriteBefore($symbols-incident,$offset);
		
	
	}
	div.leaflet-popup-content div.accident
	{
		@include partialSpriteBefore($symbols-accident,$offset);
		
		
	}
	div.leaflet-popup-content div.trafficjam
	{
		@include partialSpriteBefore($symbols-trafficjam,$offset);
		
	}
	div.leaflet-popup-content div.closure
	{
		@include partialSpriteBefore($symbols-closure,$offset);
		
	}
	div.leaflet-popup-content div.restarea
	{
		@include partialSpriteBefore($symbols-restarea,$offset);
		
	}
	div.leaflet-popup-content div.servicearea
	{
		@include partialSpriteBefore($symbols-servicearea,$offset);
		

	}
}
