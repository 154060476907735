.triptime-icon-class{     @include sprite($icons-trip-time-marker)       }
.triptime-icon-class-2{   @include sprite($icons-trip-time-marker2)      }
.triptime-icon-class-3{   @include sprite($icons-trip-time-marker3)      }
.triptime-icon-class-4{   @include sprite($icons-trip-time-marker4)      }
.triptime-icon-class-5{   @include sprite($icons-trip-time-marker5)      }
.triptime-icon-class-6{   @include sprite($icons-trip-time-marker6)      }
.triptime-icon-class-7{   @include sprite($icons-trip-time-marker7)      }
.triptime-icon-class-8{   @include sprite($icons-trip-time-marker8)      }
.triptime-icon-class-9{   @include sprite($icons-trip-time-marker9)      }
.triptime-icon-class-9p{  @include sprite($icons-trip-time-marker9p)     }
.triptime-icon-class-10{  @include sprite($icons-trip-time-marker9p)     }
/* Trip Times */
div.leaflet-popin-title.trip-time
{
    background-color: $grey11;
}
li.trip-time
{   
    font-size: simpleMap(trip-time--font-size);
}
li.trip-time div
{
    vertical-align: top;
    font-weight: bold;
    padding: 0;
}
div.trip-time-display
{
    padding-top: 0 !important;
    font-size: simpleMap(trip-time--font-size) + 0.1;
    color: $grey05;
}
div.trip-time-display.normal
{
    color: $green01;
}
div.trip-time-display.charge
{
    color: $orange01;
}
div.trip-time-display.sature
{
    color: $red02;
}
div.trip-time-display.difficile
{
    color: $red02;
}
p.trip-time-delay
{
    display: block !important;
    font-weight: normal;
    vertical-align: top;
    padding-top: 0;
    margin: 0;
    position: relative;
    font-size: 0.5em;
    top: -10px;
    @if($mode==small){
        font-size: 0.55em;
        top: -6px;
    }
}
h2.trip-time-list
{
    font-size: simpleMap(trip-time-list--font-size);
    width: 92%;
}
div.trip-time-count
{
    width: 90%;
    margin: 0 auto 15px auto;
    @if($mode==small){
        margin: 0 auto 10px auto;
    }
}
li.trip-time.origin
{
    font-size: simpleMap(trip-time-origin--font-size);
    font-weight: 700;
    height: simpleMap(trip-time-origin--height);
}
li.trip-time.origin:hover
{
    cursor: pointer;
}
li.trip-time.origin span
{
    vertical-align: middle;
    padding-top: simpleMap(trip-time-origin--top);
}
div.trip-time-list-lookup:hover
{
    cursor: pointer;
}
div.trip-time-to
{
    display: inline-block;
    @include    sprite($other-to);
    background-repeat: no-repeat;
    position: relative;
    top: 4px;
    @if($mode==small){
        top: 3px;
    }
}
div.trip-time-list-header
{
    width: 92%;
    margin: 20px auto 10px auto;
    height: simpleMap(trip-time-list-header--height);
}
span.trip-time-list-header
{
    width: simpleMap(trip-time-list-header--width);
    display: inline-block;
    line-height: 20px;
    font-size: 1em;
    @if($mode==small){
        min-width: 200px;
        max-width: 320px;
        
    }
    @if($mode==big){
        line-height: 24px;
        font-size: 1.2em;
    }
}
span.trip-time-list-header h2
{
    margin: 0;
    padding: 0;
}
p.trip-time-count
{
    padding:0;
    margin: 0;
    font-size: 1em;
    @if($mode==big){
        font-size: 1.2em;
    }
}
span.trip-time-list-lookup-icon
{
    @include sprite($icons-icon-magnifier);
    /*
    width: 45px;
    height: 45px;
    background-image: url(<?php echo $WT3_BaseUrl; ?>/icons/50/iconMagnifier.png);
    background-size: cover;
    */
    margin-left: 10px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    @if($mode==big){
        top: -20px;
    }
}
span.trip-time-list-lookup-icon-close
{
    @include sprite($buttons-button-close-list);
    margin-top: 5px;
    margin-left: 10px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}
div.trip-time-list-lookup
{
    font-size: 0.9em;
    @if($mode==big){
        font-size: 1.1em;
    }
    @if($mode==small){

        min-width: 194px;
        max-width: 314px;
    }
    display: inline-block;
    width: simpleMap(trip-time-list-header--width);
}
div.trip-time-list-lookup input
{
    margin: 0;

    padding: 0 4px 0 4px;
    width: 285px;
    height: 45px;
    font-size: 1.5em;
    @if($mode==big){

        padding: 0 4px 0 8px;
        width: 420px;
        height: 75px;
        font-size: 1.65em;
    }
    @if($mode==small){
        padding: 0 3px 0 3px;
        width: 100%;
        height: 35px;
        font-size: 1.4em;
    }
}
div.trip-time-list-lookup p
{
    padding: 0;
    margin: 0;
    width: 275px;
}
li.trip-time.global-list
{
    min-height: 60px;
    @if($mode==small){
        min-height: 45px;
    }
}
div.trip-time-description.global-list
{
    display: inline-block;
    width: simpleMap(trip-time-description-global-list--width);
    margin: auto 0 auto 0;
    padding: 0;
    vertical-align: top;
    font-size: 0.9em;
}
div.trip-time-description.global-list p
{
    margin: 0;
    padding: 0;
}
div.trip-time-description.global-list p.trip-time-description-destination
{
    position: relative;
    top: 3px;
}
span.trip-time-description-to
{
    color: $grey02;
    padding-left: 5px;
}
div.trip-time-display.global-list
{
    display: inline-block;
    width: simpleMap(trip-time-display-global-list--width);
    margin: auto 0 auto 15px;
    padding: 0;
    font-size: 1.2em;
    vertical-align: top;
}
div.trip-time-display.global-list p.trip-time-current-time
{
    margin: 0;
    padding: 0;
}
div.trip-time-display.global-list p.trip-time-normal-time
{
    font-size: 0.5em;
     @if($mode==small){
        font-size: 0.65em;
    }
    margin: 0;
    padding: 0;
}

@if($mode==small){
    .leaflet-bottom{
        .leaflet-bar.leaflet-customButtonBar.leaflet-control{
            width: 100%;
            background: white;
            order: 2;
            margin-bottom: 0;
            a{
                margin: auto;
            }
        }
    }
}